.new-message-container {
    position: relative;
}

.new-message {
    cursor: pointer;
}

.dropdown {
    width: 350px;
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black;
    padding: 10px;
    z-index: 1000;
    height: 640px;
}


.dropdown ul {
    list-style: none;
    padding: 0;
    max-height: 522px;
    overflow-y: auto;
    margin: 0;
    flex-grow: 1;
}

.dropdown li {
    padding: 5px 10px;
    cursor: pointer;
    color: black;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}