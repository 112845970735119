/* styles.module.css */

.calendarContainer {
  width: 80%; /* Ancho personalizado */
  margin: 0 auto; /* Centrar horizontalmente */
  padding: 20px; /* Espaciado opcional */
}

.contenedorCargando {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.patient-list {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  max-height: 150px;
  overflow-y: auto; 
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.patient-list li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.patient-list li:hover {
  background-color: #f5f5f5;
}

.navbar {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbarMenu {
  list-style: none;
  padding: 0;
  display: flex;
}

.navbarMenu li {
  margin: 0 10px;
}

.navbarButton {
  padding: 10px 20px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

.navbarButton:hover {
  background-color: #777;
}