.custom-modal {
    .modal-content {
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);

        }
    }
    .modal-header {
        color: black;
        border-bottom: 1px solid #88D7D5;
    }

    .modal-footer {
        border-top: 1px solid #dee2e6;
    }

    .form-label {
        font-weight: bold;
        text-align: center;
    }
    
    .form-control {
        border-radius: 10px;
        border: 1px solid #dee2e6;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    }
    

    .btn-primary {
        background-color: #42c5e3;
        border-radius: 10px;
        border: 1px solid #dee2e6;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    }

    .btn-secondary {
        background-color: #f35536;
        border-radius: 10px;
        border: 1px solid #dee2e6;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    }
}