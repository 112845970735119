.indicaciones {
    display:flex;
    width: 100%;
    background-color: aqua;
}

.container-derecha {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.title-0 {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
    color: #313131; 
}

.title-1 {
    font-size: 20px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 10px;
}

.title-2 {
    font-size: 25px;
    font-weight: 600;
    color: #2c3e50; 
    margin-bottom: 10px;
}



.formulario {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formulario span {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    color: #555;
}

.formulario input {
    margin-bottom: 15px;
    padding: 12px;
    width: calc(100% - 24px);
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 16px; 
    color: #555;
}

.formulario select{
    margin-bottom: 15px;
    padding: 12px;
    width: calc(100% - 24px);
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 16px; 
    color: #555;
}