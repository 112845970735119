.st0 {
  fill: #009045;
  stroke: #000000;
  strokemiterlimit: 10;
}
.st1 {
  fill: #f7ec45;
  stroke: #000000;
  strokemiterlimit: 10;
}
.st2 {
  fill: #5fa199;
  stroke: #000000;
  strokemiterlimit: 10;
}
.st3 {
  fill: #bf0811;
  stroke: #000000;
  strokemiterlimit: 10;
}
.st4 {
  fill: #e6b54a;
}
