body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.chat-container {
  display: flex;
  height: 85vh;
  background-color: #f0f2f5;
  padding: 5px;
}

.sidebar {
  width: 30%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
}

.sidebar-header {
  padding: 8px;
  background-color: #075e54;
  color: #fff;
  font-weight: bold;
}

.header-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-message {
  cursor: pointer;
}

.logo-whatsapp {
  display: flex;
}

.header-logo svg {
  margin-right: 10px;
}

.header-logo h3 {
  font-size: 18px;
  margin-top: 10px;
}

.chat-header h4 {
  font-size: 18px;
  margin: 4px;
  padding: 3px;
}

.search-bar {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 20px;
  background-color: #f1f1f1;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-item:hover,
.user-item.active {
  background-color: #ebebeb;
}

.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #bdbdbd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
}

.user-info h4 {
  margin: 0;
  font-size: 16px;
}

.user-info p {
  margin: 0;
  color: #888;
  font-size: 14px;
}

.chat-window {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #e5ddd5;
}

.chat-header {
  padding: 10px;
  background-color: #075e54;
  color: #fff;
}

.messages {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  overflow-y: auto;
  background-image: url('https://static.whatsapp.net/rsrc.php/v3/yl/r/gi_DckOUM5a.png');
  background-size: cover;
  background-position: center;
  height: 100%;
}

.message.received::after {
  background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/cTpzmA9a2VH.png) 50% 50% no-repeat;
  background-size: contain;
  content: '';
  height: 31px;
  left: -12px;
  position: absolute;
  top: -6px;
  width: 12px;
}

.message {
  display: inline-block;
  max-width: 60%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 7.5px;
  border-top-left-radius: 0;
  background-color: #fff;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .15);
  position: relative;
  font-size: 14px;
  word-wrap: break-word;

}

.message.received {
  background-color: #fff;
  align-self: flex-start;
  border-radius: 8px 8px 8px 0;
  border-top-left-radius: 0;
  min-width: 100px;

}

.message.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
  border-radius: 8px 8px 0 8px;
  margin-top: 10px;
  min-width: 100px;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 30px;
  background-color: #f1f1f1;
  margin-right: 10px;
}

.message-input button {
  padding: 10px 20px;
  background-color: #075e54;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.center-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.center-text h1 {
  margin-bottom: 10px;
}

.center-text span {
  font-size: 16px;
  color: #555;
}

.timestamp {
  bottom: 2px;
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  height: 15px;
  line-height: 12px;
  position: absolute;
  right: 7px;
}

.date-label {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 1rem 0;
  color: #555;
}

.message p {
  white-space: pre-line;
}

.user-info {
  width: 330px;
}

.single-line-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.not_read {
  background-color: #00a884;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  margin-left: 10px;
}